import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Collapsible from "react-collapsible"
import CollapsibleItem from "./collapsibleItemMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SideBar = styled.aside`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3;
  overflow-y: auto;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  nav {
    background: transparent;
    padding: 1.5rem;
    a:hover {
      border-bottom: 2px solid var(--main-white);
    }
  }
  .closeMenuBtn {
    background: transparent;
    border: none;
    color: var(--main-white);
    font-size: 2rem;
    cursor: pointer;
    padding-right: 0;
  }
  .Collapsible__contentInner {
    ul {
      padding-left: 0.5rem;
    }
  }
  .Collapsible__trigger {
    cursor: pointer;
    user-select: none;
    svg {
      transition: transform 300ms;
    }
    &.is-open svg {
      transform: rotateZ(90deg);
    }
  }
`

const CloseMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 70px;
  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
  @media (max-width: 475px) {
    padding: 0rem 2rem;
  }
`

const sideNav = ({ open, setOpen }) => {
  return (
    <SideBar open={open}>
      <CloseMenu className="container">
        <button
          className="closeMenuBtn"
          onClick={() => setOpen(false)}
          aria-label="Close Menu"
        >
          <FontAwesomeIcon icon="times" />
        </button>
      </CloseMenu>
      <nav className="container">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <Collapsible
            trigger={<CollapsibleItem text="Getting Started" />}
            contentContainerTagName="li"
          >
            <ul>
              <li>
                <Link to="/first-steps/">New to the Game</Link>
              </li>
              <li>
                <Link to="/coming-from-fifa/">
                  Coming from FIFA's Ultimate Team
                </Link>
              </li>
              <li>
                <Link to="/coins-gp/">Coins / GP / eFootball Points</Link>
              </li>
              <li>
                <Link to="/game-modes-challenges/">
                  Game Modes and Weekly Challenges
                </Link>
              </li>
            </ul>
          </Collapsible>
          <Collapsible
            trigger={<CollapsibleItem text="Players" />}
            contentContainerTagName="li"
          >
            <ul>
              <li>
                <Link to="/signing-new-players-agents/">
                  Signing New Players (via Agents)
                </Link>
              </li>
              <li>
                <Link to="/signing-new-players-scout-combinations/">
                  Signing New Players (via Scout Combinations)
                </Link>
              </li>
              <li>
                <Link to="/trading-players/">Trading Players</Link>
              </li>
              <li>
                <Link to="/legends/">Legends</Link>
              </li>
              <li>
                <Link to="/training-players/">Training Players</Link>
              </li>
              <li>
                <Link to="/player-form-condition-rating/">
                  Player Form and Live Condition Rating
                </Link>
              </li>
              <li>
                <Link to="/best-players-ball-type/">
                  Best Players per Ball Type
                </Link>
              </li>
            </ul>
          </Collapsible>
          <Collapsible
            trigger={<CollapsibleItem text="Managers" />}
            contentContainerTagName="li"
          >
            <ul>
              <li>
                <Link to="/signing-managers/">
                  Signing Managers and Assistants
                </Link>
              </li>
              <li>
                <Link to="/manager-upgrades/">Manager Upgrades</Link>
              </li>
              <li>
                <Link to="/best-managers/">Best Managers</Link>
              </li>
            </ul>
          </Collapsible>
          <Collapsible
            trigger={<CollapsibleItem text="Game Data" />}
            contentContainerTagName="li"
          >
            <ul>
              <li>
                <Link to="/playing-styles/">Playing Styles</Link>
              </li>
              <li>
                <Link to="/player-attributes/">Player Attributes</Link>
              </li>
              <li>
                <Link to="/player-skills/">Player Skills</Link>
              </li>
              <li>
                <Link to="/advanced-instructions/">Advanced Instructions</Link>
              </li>
            </ul>
          </Collapsible>
          <li>
            <FontAwesomeIcon icon="clipboard-list" />{" "}
            <Link to="/managers/">Manager List</Link>
          </li>
          <li>
            <Link to="/contract-stamina-renewal/">
              Contract and Stamina Renewal
            </Link>
          </li>
          <li>
            <FontAwesomeIcon icon="star" />{" "}
            <Link to="/gameplay-tips/">Gameplay Tips</Link>
          </li>
          <li>
            <Link to="/how-to-get-more-gp/">How to get more GP</Link>
          </li>
          <li>
            <Link to="/myclub-tips-tricks/">MyClub Tips and Tricks</Link>
          </li>
          <li>
            <Link to="/resources/">Resources</Link>
          </li>
          <li>
            <Link to="/faq/">Frequently Asked Questions</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
          <li>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </li>
        </ul>
      </nav>
    </SideBar>
  )
}

export default sideNav
