import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DarkModeBtn from "./darkModeToggler"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  z-index: 3;
`

const HeaderSection = styled.section`
  background-color: var(--light-blue);
  color: var(--main-black);
  padding: 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  button {
    font-size: 1rem;
    width: 1rem;
    padding-left: 0;
    border: none;
    background: transparent;
    color: var(--main-white);
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    width: 100%;
  }
  a {
    color: var(--main-white);
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid var(--main-white);
    }
  }
`

const Header = ({ open, setOpen }) => (
  <HeaderContainer>
    <HeaderSection>
      <div className="container" style={{ padding: "1.5rem" }}>
        <button onClick={() => setOpen(!open)} aria-label="Toggle Navigation">
          <FontAwesomeIcon icon="bars" />
        </button>
        <Link to="/">PES MyClub Guide</Link>
        <DarkModeBtn />
      </div>
    </HeaderSection>
  </HeaderContainer>
)

export default Header
