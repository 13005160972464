import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const darkModeToggler = () => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <label
          style={{
            cursor: "pointer",
            width: "1rem",
            paddingLeft: "1rem",
          }}
          aria-label="Toggle Light or Dark Mode"
        >
          <input
            type="checkbox"
            onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
            checked={theme === "dark"}
            style={{ display: "none" }}
          />{" "}
          {theme === "dark" ? (
            <FontAwesomeIcon
              icon="lightbulb-on"
              style={{
                color: "var(--main-white)",
                borderBottom: "2px solid transparent",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon="moon"
              style={{
                color: "var(--main-white)",
                borderBottom: "2px solid transparent",
              }}
            />
          )}
        </label>
      )}
    </ThemeToggler>
  )
}

export default darkModeToggler
