/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import { library, config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import {
  faLightbulbOn,
  faMoon,
  faBars,
  faChevronRight,
  faTimes,
  faClipboardList,
  faStar,
  faSearch,
  faFilter,
} from "@fortawesome/pro-solid-svg-icons"
import {
  faUserTie,
  faTrophyAlt,
  faFutbol,
} from "@fortawesome/pro-duotone-svg-icons"

import Header from "./header"
import SideNav from "./sideNav"
import Footer from "./footer"
import "./layout.css"

library.add(
  faLightbulbOn,
  faMoon,
  faBars,
  faChevronRight,
  faTimes,
  faClipboardList,
  faStar,
  faSearch,
  faFilter,
  faUserTie,
  faTrophyAlt,
  faFutbol
)
config.autoAddCss = false

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${({ open }) => (open ? "hidden" : "visible")};
  }
`

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <GlobalStyle open={open} />
      <Header open={open} setOpen={setOpen} />
      <SideNav open={open} setOpen={setOpen} />
      <main style={{ paddingTop: "4rem" }}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
