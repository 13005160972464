import React from "react"

const footer = () => {
  return (
    <footer>
      <div className="container">
        <p>
          Written and coded in sunny Lisbon by{" "}
          <a
            href="https://brunobrito.pt"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bruno Brito
          </a>{" "}
          🇵🇹
        </p>
      </div>
    </footer>
  )
}

export default footer
